import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

// Fix missing default icon
import L from "leaflet";
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -32],
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = () => {
  const position = [-35.279525027960204, 149.13334155198518]; // Replace with your desired initial coordinates

  return (
    <MapContainer
      center={position}
      zoom={15}
      style={{ height: "350px", width: "100%" }}
    >
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a>'
      />
      <Marker position={position}>
        <Popup>
          Test location. <br /> Coming soon.
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
