import "../styles.scss";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "../components/ui/navigation-menu";
import Map from "./Map";
import { Button } from "../components/ui/button";

let menuData = [
  {
    href: "/",
    name: "Home",
  },
  {
    href: "#about-us",
    name: "About Us",
  },
  {
    href: "#products",
    name: "Products",
  },
];

let gachaListData = [
  {
    name: "Sample gacha 1",
    icon: "ball1",
  },
  {
    name: "Sample gacha 2",
    icon: "ball2",
  },
  {
    name: "Sample gacha 3",
    icon: "ball3",
  },
  {
    name: "Sample gacha 4",
    icon: "ball4",
  },
  {
    name: "Sample gacha 5",
    icon: "ball5",
  },
  {
    name: "Sample gacha 6",
    icon: "ball6",
  },
  {
    name: "Sample gacha 7",
    icon: "ball7",
  },
  {
    name: "Sample gacha 8",
    icon: "ball8",
  },
  {
    name: "Sample gacha 9",
    icon: "ball9",
  },
  {
    name: "Sample gacha 10",
    icon: "ball10",
  },
  {
    name: "Sample gacha 11",
    icon: "ball11",
  },
  {
    name: "Sample gacha 12",
    icon: "ball12",
  },
];

function App() {
  return (
    <div className="gachamine">
      <h1 className="invisible h-0 w-0">Gachamine</h1>
      <header className=" backdrop-blur supports-[backdrop-filter]:bg-background/40 z-[9999] shadow-md header  py-5 px-12 sticky top-0 inset-x-0 bg-white flex justify-center">
        <div className="max-w-4xl justify-between flex w-full">
          <div className="logo bg-cover text-6xl">
            <span className="gacha">Gacha</span>mine
          </div>
          <NavigationMenu className="">
            <NavigationMenuList className="flex gap-5">
              {menuData.map((item, index) => {
                return (
                  <NavigationMenuItem key={index}>
                    <NavigationMenuLink
                      href={item.href}
                      className={navigationMenuTriggerStyle()}
                    >
                      {item.name}
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                );
              })}
            </NavigationMenuList>
          </NavigationMenu>
          <div className="hidden-block"></div>
        </div>
      </header>
      <main>
        <div className="block-container">
          <div className="hero bg-no-repeat bg-cover flex justify-end">
            <h2 className="text-center tracking-widest text-7xl text-slate-50 font-bold h-full flex items-center justify-center p-32 w-full leading-normal">
              Unleash the Fun of Gacha Vending!
            </h2>
          </div>
        </div>

        <div className="block-container relative">
          <div className="z-20 relative about-us max-w-4xl mx-auto py-40">
            <div className="z-[-1] relative bg-ball-image"></div>
            <h2
              className="heading-underline font-bold text-xl mb-20 uppercase"
              id="about-us"
            >
              About Us
            </h2>
            <div className="content quote-block text-lg">
              At Gachamine, we're dedicated to bringing the joy of discovery and
              excitement to the heart of Canberra's shopping experience. With
              our vibrant gacha vending machines nestled in the city's bustling
              malls, we've redefined the art of surprise, offering a world of
              whimsical treasures at your fingertips. Step into a world where
              every twist of the crank or turn of the dial reveals a miniature
              marvel. Our carefully curated collection of gacha capsules
              promises a thrilling journey through a universe of collectibles,
              trinkets, and tiny treasures. Whether you're a seasoned collector
              or simply seeking a touch of whimsy, our machines hold the promise
              of delightful surprises for all. Our commitment to quality,
              variety, and exceptional customer service has earned us a
              reputation as Canberra's premier gacha vending destination. Join
              us in celebrating the art of the unexpected, and let the magic of
              gacha vending brighten your day. Explore our enchanting machines,
              meet our passionate team, and discover the wonders that await you
              in the world of Gachamine. Your next delightful surprise is just a
              spin away!
            </div>
          </div>
        </div>

        <div className="block-container">
          <div className="products max-w-4xl mx-auto py-40">
            <h2
              className="heading-underline font-bold text-xl mb-20 uppercase"
              id="products"
            >
              Products
            </h2>
            <div className="gacha-list flex justify-between flex-wrap gap-x-5 gap-y-10">
              {gachaListData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className=" bg-white p-9 rounded-lg shadow-lg"
                  >
                    <div className={item.icon + " mx-auto"}></div>
                    <p className="text-center font-bold">{item.name} </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="block-container">
          <div className="call-to-action max-w-4xl mx-auto py-40 relative z-20">
            <div className="z-[-1] relative bg-join-us-image"></div>
            <h2
              className="heading-underline font-bold text-xl mb-20 uppercase"
              id="join-us"
            >
              Join Us
            </h2>
            <div className="content text-lg">
              "If the idea of having our cutting-edge vending machines in your
              store appeals to you, we're excited about the possibility of
              forging a partnership that promises mutual profit and growth.
              Let's embark on this journey together and discover the path to
              shared success."
            </div>

            <div className="text-center mt-20">
              <Button variant="default" size="lg">
                <a href="mailto:cbraktoy@gmail.com" className="mx-auto text-lg">
                  Join Us Now
                </a>
              </Button>
            </div>
          </div>
        </div>

        <div className="block-container">
          <div className="map max-w-4xl mx-auto py-40">
            <h2
              className="heading-underline font-bold text-xl mb-20 uppercase"
              id="map"
            >
              Map
            </h2>
            <h3 className="font-bold">Address:</h3>
            <p>148 Bunda St, </p>
            <p>Canberra ACT 2608</p>
            <br />
            <Map />
          </div>
        </div>

        <div className="block-container">
          <div className="contact-us max-w-4xl mx-auto py-40">
            <h2
              className="heading-underline font-bold text-xl mb-20 uppercase"
              id="contact-us"
            >
              Contact Us
            </h2>
            <p>Email: cbraktoy@gmail.com</p>
          </div>
        </div>
      </main>
      <footer className="footer bg-main2 py-28">
        <div className="text-center ">
          <h2 className="">Copyright 2023 Gachamine / CBR AK Toy</h2>
        </div>
      </footer>
    </div>
  );
}

export default App;
